const baseUrl = window.location.origin;

export const getEndpoint = () => {
  if (baseUrl == 'https://bot.notstore.cc') {
    return 'https://server.notstore.cc/api';
  }

  if (baseUrl == 'https://bot-test.notstore.cc') {
    return 'https://server-test.notstore.cc/api';
  }

  if (baseUrl == 'http://localhost:3000') {
    return 'https://server-test.notstore.cc/api';
  }

  return 'https://server-test.notstore.cc/api';
};

export const getShareLink = () => {
  if (baseUrl == 'https://bot.notstore.cc') {
    return 't.me/thenotstorebot/thenotstorebot?startapp=store-';
  }

  if (baseUrl == 'https://bot-test.notstore.cc') {
    return 't.me/emvxsibot/thenotstoretest?startapp=store-';
  }

  if (baseUrl == 'http://localhost:3000') {
    return 't.me/emvxsibot/thenotstoretest?startapp=store-';
  }

  return 't.me/emvxsibot/thenotstoretest?startapp=store-';
};
