import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Spinner,
  Stack,
  Text,
  Th,
  Input,
  Center,
  Card,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  Heading,
  Avatar,
  useToast,
  Image,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import ArrowSvg from '../assets/svg/arrow-left.svg';

import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import GiftSvg from '../assets/svg/gift.svg';

import DarkGiftSvg from '../assets/svg/dark-gift.svg';

import CoinSvg from '../assets/svg/coin.svg';

import BigCoinSvg from '../assets/svg/bigCoin.svg';

import CreateStoreSvg from '../assets/svg/create-store.svg';

import TelegramSvg from '../assets/svg/telegram.svg';

import TwitterSvg from '../assets/svg/twitter.svg';

import BagSvg from '../assets/svg/bag.svg';

import FriendsSvg from '../assets/svg/friends.svg';

import TaskCompleteSvg from '../assets/svg/task-complete.svg';
import TaskInCompleteSvg from '../assets/svg/task-incomplete.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { toggleDarkMode } from '../redux/slices/settings';
import { Player } from '@lottiefiles/react-lottie-player';
import { claimAReward, getMyRewards } from '../redux/slices/rewards';
import { getMyProfile, login } from '../redux/slices/auth';
import { formatNumberWithCommas, isValidDateString } from '../helpers/helpers';

export default function Community() {
  const { colorMode, toggleColorMode } = useColorMode();
  const toast = useToast();

  const text = useColorModeValue('white', 'gray.800');

  let chatInfo = window.Telegram.WebApp.initDataUnsafe.user;

  const rewardState = useSelector(state => state.reward);

  const authState = useSelector(state => state.auth);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const [input, setInput] = React.useState({});

  const [selectedReward, setSelectedReward] = React.useState();

  const getUpdateUserToken = () => {
    if (!chatInfo?.id) {
      return { email: '', password: '', name: '' };
    }
    const input = chatInfo?.id + '-' + chatInfo?.username;

    const result = input.split(/-/);

    // const result = ['2037125694', 'Raphael'];

    const email = 'bot' + result[0] + '@telegram.com';

    const password = result[0] + 'botPass';

    return { email, password };
  };

  React.useEffect(() => {
    if (authState.token) {
      // dispatch(getMyProfile());
      dispatch(getMyRewards());
    } else {
      handleLogin();
    }
  }, [authState.token, rewardState?.reload]);

  const handleLogin = () => {
    const { email, password } = getUpdateUserToken();

    dispatch(login({ email, password }));
  };

  const claim = item => {
    if (item?.input_fields.length > 0) {
      setSelectedReward(item);

      onOpen();
      return;
    }

    const callback = responseData => {
      if (Object.keys(responseData).length > 0) {
        toast({
          title: 'Unable to claim.',
          description: JSON.stringify(responseData?.message),
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        });

        return;
      }

      onOpen();
      // Perform additional actions based on login response
    };

    dispatch(
      claimAReward({
        id: item.id,
        callback,
      })
    );
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    dispatch(
      claimAReward({
        id: selectedReward.id,
        ...input,
      })
    );

    setSelectedReward([]);
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        pb={{ base: '90px', md: '90px' }}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={7}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() =>
                id ? navigate(`/store/${id}`) : navigate(`/dashboard`)
              }
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>COMMUNITY</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Flex direction={'column'} px={{ base: '20px', md: '20px' }}>
          <Text fontSize={'14px'} fontWeight={'400'} lineHeight={'19.6px'}>
            Join us on our social media channels to earn{' '}
            <span style={{ color: 'rgba(36, 107, 253, 1)', fontWeight: '700' }}>
              NOT POINTS
            </span>
            , get special invites to our events, exclusive promotions, updates,
            and the latest news! Follow us now!
          </Text>
          <Heading
            fontWeight={'600'}
            fontSize={'16px'}
            mt={'20px'}
            mb={'10px'}
            lineHeight={'18px'}
          >
            Points Gained
          </Heading>

          <Card
            p={4}
            alignItems={'center'}
            borderRadius={'8px'}
            flexDirection={'row'}
          >
            <Image src={colorMode == 'light' ? GiftSvg : DarkGiftSvg} mr={3} />

            <Box>
              <Text
                fontSize={'16px'}
                lineHeight={'18px'}
                fontWeight={'400'}
                mb={'4px'}
                display={'flex'}
                flexDirection={'row'}
              >
                <Image src={BigCoinSvg} mx={1} />{' '}
                {authState?.user?.points
                  ? formatNumberWithCommas(authState?.user?.points)
                  : '0'}{' '}
                Not Points
              </Text>

              <Text
                fontSize={'10px'}
                lineHeight={'10px'}
                fontWeight={'400'}
                color={'rgba(108, 117, 125, 1)'}
                alignItems={'center'}
                display={'flex'}
                flexDirection={'row'}
              >
                {authState?.token
                  ? 'Rewards Balance'
                  : 'Place an Order to earn first points'}
              </Text>
            </Box>
          </Card>

          {authState?.token && (
            <Heading
              mt={'20px'}
              mb={'10px'}
              fontWeight={'600'}
              fontSize={'16px'}
              lineHeight={'18px'}
            >
              Activity Tracker
            </Heading>
          )}

          {authState.token &&
            rewardState?.myRewards &&
            (rewardState?.myRewards ?? []).length > 0 &&
            rewardState?.myRewards.map((item, index) => (
              <>
                {isValidDateString(item?.configuration?.validity) && (
                  <Card
                    p={4}
                    alignItems={'center'}
                    borderRadius={'8px'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={'10px'}
                    onClick={() => {
                      if (item.users?.length == 0) {
                        claim(item);
                      }
                    }}
                    key={index}
                  >
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      flexDirection={'row'}
                    >
                      <Image
                        height={'40px'}
                        width={'40px'}
                        borderRadius={'8px'}
                        src={item?.picture || CreateStoreSvg}
                        mr={3}
                      />

                      <Box>
                        <Text
                          fontSize={'16px'}
                          lineHeight={'18px'}
                          fontWeight={'400'}
                          mb={'4px'}
                        >
                          {item?.title}
                        </Text>

                        <Text
                          fontSize={'10px'}
                          lineHeight={'10px'}
                          fontWeight={'400'}
                          color={'rgba(108, 117, 125, 1)'}
                          alignItems={'center'}
                          display={'flex'}
                          flexDirection={'row'}
                        >
                          Earn <Image src={CoinSvg} mx={1} />
                          {formatNumberWithCommas(item?.points)} Not Points
                        </Text>
                      </Box>
                    </Box>

                    {item.users?.length > 0 ? (
                      <Image src={TaskCompleteSvg} />
                    ) : (
                      <Image src={TaskInCompleteSvg} />
                    )}
                  </Card>
                )}
              </>
            ))}
        </Flex>
      </Flex>

      <Modal size={'xs'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={'24px'} alignSelf={'center'}>
          <ModalHeader fontSize={'16px'} lineHeight={'18px'} fontWeight={'600'}>
            Rewards Update !!!
          </ModalHeader>
          <ModalCloseButton />
          {selectedReward && (selectedReward?.input_fields ?? []).length > 0 ? (
            <ModalBody>
              <Stack mb={8} spacing={4}>
                {selectedReward?.input_fields.map((rew, index) => (
                  <Input
                    key={index}
                    height={'56px'}
                    variant="filled"
                    type={rew.type ?? 'email'}
                    fontSize={'12px'}
                    placeholder={rew?.title}
                    name={rew?.name}
                    _placeholder={{ color: 'gray.500' }}
                    onChange={handleChange}
                  />
                ))}

                <Center width={'100%'}>
                  <Button
                    onClick={() => handleSubmit()}
                    loadingText="Submitting"
                    bgColor={'rgba(36, 107, 253, 1)'}
                    color={'white'}
                    fontSize={'14px'}
                    lineHeight={'21px'}
                    fontWeight={'500'}
                    alignSelf={'center'}
                    borderRadius={'16px'}
                    width={'100%'}
                    height={'54px'}
                    isDisabled={
                      Object.keys(input).length !=
                        (selectedReward?.input_fields ?? []).length ||
                      Object.values(input).some(item => !item)
                    }
                  >
                    Submit
                  </Button>
                </Center>
              </Stack>
            </ModalBody>
          ) : (
            <>
              {rewardState?.isLoading ? (
                <ModalBody>
                  <Center flexDirection={'column'}>
                    <Spinner
                      thickness="6px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                      alignSelf={'center'}
                    />
                    <Text
                      mt={2}
                      textAlign={'center'}
                      fontSize={'14px'}
                      lineHeight={'19.6px'}
                      fontWeight={'400'}
                    >
                      Validating...
                    </Text>
                  </Center>
                </ModalBody>
              ) : (
                <ModalBody>
                  <Text
                    fontSize={'14px'}
                    lineHeight={'19.6px'}
                    fontWeight={'400'}
                  >
                    Congratulations! 🎉 You've just claimed some reward points.
                    Keep completing tasks to rack up even more points. The
                    adventure has just begun!
                  </Text>

                  <Player
                    style={{
                      height: '184px',
                      width: '100%',
                    }}
                    src={
                      'https://lottie.host/3693bc50-d2c9-4750-a411-c131aff5a2e3/haZudqU9b2.json'
                    }
                    autoplay
                    loop
                  />
                </ModalBody>
              )}
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
