import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Heading,
  CardHeader,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Image,
  Card,
  CardBody,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  useColorModeValue,
  useColorMode,
  Textarea,
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';

import { BiArrowBack } from 'react-icons/bi';

import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import WalletSvg from '../assets/svg/wallet.svg';

import ArrowSvg from '../assets/svg/arrow-left.svg';

import { MdKeyboardArrowRight } from 'react-icons/md';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { login, register, registerCompany } from '../redux/slices/auth';
import { toggleDarkMode } from '../redux/slices/settings';

import {
  useTonConnectModal,
  useTonWallet,
  useTonConnectUI,
} from '@tonconnect/ui-react';
import { storeWallet } from '../redux/slices/wallet';
import { getMyStore } from '../redux/slices/store';
import { FaInfoCircle } from 'react-icons/fa';
import { generateRandomAlphanumericName } from '../helpers/helpers';

export default function CreateStore() {
  const { colorMode, toggleColorMode } = useColorMode();

  const { state, open, close } = useTonConnectModal();

  const wallet = useTonWallet();

  const text = useColorModeValue('white', 'gray.800');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  let chatInfo = window.Telegram.WebApp.initDataUnsafe.user;

  const authState = useSelector(state => state.auth);

  const storeState = useSelector(state => state.store);

  const [input, setInput] = React.useState({
    store_name: '',
    store_description: '',
    store_phone: '',
  });

  const [errors, setErrors] = React.useState({});

  const getUpdateUserToken = () => {
    const input = chatInfo?.id + '-' + chatInfo?.username;

    // const input = '2037125694-Raphael';

    const result = input.split(/-/);

    const email = 'bot' + result[0] + '@telegram.com';

    const password = result[0] + 'botPass';

    const name = result[1] ? result[1] : generateRandomAlphanumericName();

    return { email, password, name };
  };

  const validateForm = () => {
    let formErrors = {};

    if (input.store_name.length < 2 || input.store_name.length > 60) {
      formErrors.store_name =
        'store name is required and length greater than 2 and less than 60';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  React.useEffect(() => {
    handleLogin();
  }, []);

  const handleLogin = () => {
    const { email, password, name } = getUpdateUserToken();

    dispatch(login({ email, password }));
  };

  const handleSubmit = () => {
    const { email, password, name } = getUpdateUserToken();

    if (authState?.token) {
      if (validateForm()) {
        const callback = () => {
          handleLogin();
        };
        dispatch(
          registerCompany({
            ...input,
            name,
            callback,
          })
        );
      }

      return;
    }

    if (validateForm()) {
      dispatch(
        register({
          ...input,
          email: email,
          password: password,
          password_confirmation: password,
          name,
        })
      );
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleConnectWallet = () => {
    open();
  };

  React.useEffect(() => {
    if (storeState.myStores.length > 0) {
      navigate('/');
    }
  }, [storeState.myStores]);

  React.useEffect(() => {
    if (authState?.token && authState?.team) {
      dispatch(
        storeWallet({
          name: 'ton coin',
          symbol: 'TON',
          address: wallet?.account?.address,
        })
      );
      dispatch(getMyStore());
      // navigate('/');
    }
  }, [authState?.token]);

  return (
    <>
      <Flex
        flexDirection="column"
        height="100vh"
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/`)}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              {/* <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              /> */}
              <Text fontSize={'16px'}>CREATE STORE</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Center px={{ base: '20px', md: '20px' }} flexDirection="column">
          <Stack spacing="4">
            <Card width={'100%'}>
              <FormControl id="store_name" isInvalid={errors.store_name}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  pr={4}
                >
                  <Input
                    type="text"
                    height={'56px'}
                    pl={2}
                    variant="unstyled"
                    fontSize={'12px'}
                    lineHeight={'16.8px'}
                    fontWeight={'500'}
                    name="store_name"
                    borderColor={'rgba(208, 207, 207, 1)'}
                    width={{ sm: '340px', md: '382px' }}
                    value={input.store_name}
                    placeholder="Store Name eg. Bill's Store"
                    onChange={handleChange}
                  />
                  <Popover>
                    <PopoverTrigger>
                      <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Store Name</PopoverHeader>
                      <PopoverBody fontSize={'12px'}>
                        Your store name is how customers will know your
                        business. Pick a name that matches your brand, is easy
                        to remember, and relates to what you sell. This name
                        will be used everywhere for your store.
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>
                <FormErrorMessage>{errors.store_name}</FormErrorMessage>
              </FormControl>
            </Card>

            <Card>
              <FormControl id="store_phone" isInvalid={errors.store_phone}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  pr={4}
                >
                  <Input
                    max={10}
                    type="number"
                    variant="unstyled"
                    fontSize={'12px'}
                    lineHeight={'16.8px'}
                    fontWeight={'500'}
                    name="store_phone"
                    borderColor={'rgba(208, 207, 207, 1)'}
                    height={'56px'}
                    pl={2}
                    width={{ sm: '340px', md: '382px' }}
                    placeholder="Contact number"
                    value={input.store_phone}
                    onChange={handleChange}
                  />
                  <Popover>
                    <PopoverTrigger>
                      <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Store Contact Number</PopoverHeader>
                      <PopoverBody fontSize={'12px'}>
                        Provide a contact number where customers can reach you
                        for inquiries or support. Ensure it’s a number that you
                        actively monitor.
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>

                <FormErrorMessage>{errors.store_phone}</FormErrorMessage>
              </FormControl>
            </Card>

            <Card>
              <FormControl
                id="store_description"
                isInvalid={errors.store_description}
              >
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  pr={4}
                >
                  <Textarea
                    height={'50px'}
                    pl={2}
                    noOfLines={4}
                    fontSize={'12px'}
                    lineHeight={'16.8px'}
                    fontWeight={'500'}
                    variant="unstyled"
                    name="store_description"
                    borderColor={'rgba(208, 207, 207, 1)'}
                    width={{ sm: '328px', md: '380px' }}
                    placeholder="Store Description"
                    value={input.store_description}
                    onChange={handleChange}
                  />
                  <Popover>
                    <PopoverTrigger>
                      <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Store Description</PopoverHeader>
                      <PopoverBody fontSize={'12px'}>
                        Briefly describe what your store offers. This helps
                        customers understand your business and what they can
                        expect to find.
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>

                <FormErrorMessage>{errors.store_description}</FormErrorMessage>
              </FormControl>
            </Card>
          </Stack>
          <Card
            mt={10}
            p={4}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            width={'100%'}
            onClick={() => handleConnectWallet()}
          >
            <Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
              <Image src={WalletSvg} mr={3} />

              <Text
                noOfLines={1}
                fontSize={'12px'}
                lineHeight={'18px'}
                width={'90%'}
                fontWeight={'500'}
              >
                {wallet?.account?.address
                  ? wallet?.account?.address.substring(0, 25) + '...'
                  : 'Connect to receive payment'}
              </Text>
            </Box>

            <Box>
              <MdKeyboardArrowRight
                size={30}
                color={
                  colorMode == 'light' ? 'black' : 'rgba(155, 155, 155, 1)'
                }
              />
            </Box>
          </Card>

          <Text
            fontSize={'10px'}
            fontWeight={'400'}
            lineHeight={'12px'}
            color={'rgba(52, 75, 103, 1)'}
            mt={2}
            alignSelf={'flex-start'}
          >
            Easily receive payments by connecting your wallet when setting up
            your store. Start earning instantly!
          </Text>
        </Center>

        {/* <Flex
          px={{ base: '20px', md: '20px' }}
          mt={10}
          direction={'row'}
          justifyContent={'space-between'}
          width={'90%'}
        >
          <Button
            onClick={() => navigate(`/`)}
            isLoading={authState?.isLoading}
            loadingText="Back"
            bgColor={'rgba(215, 226, 244, 1)'}
            color={'rgba(36, 107, 253, 1)'}
            fontSize={'14px'}
            lineHeight={'21px'}
            fontWeight={'500'}
          >
            Back
          </Button>

          <Button
            onClick={() => handleSubmit()}
            isLoading={authState?.isLoading}
            loadingText="Submitting"
            bgColor={'rgba(36, 107, 253, 1)'}
            color={'white'}
            fontSize={'14px'}
            lineHeight={'21px'}
            fontWeight={'500'}
            isDisabled={
              !input?.store_name ||
              !input?.store_description ||
              !input?.store_phone ||
              !wallet?.account?.address
            }
          >
            Continue
          </Button>
        </Flex> */}

        <Center width={'100%'} marginY={10}>
          <Button
            onClick={() => handleSubmit()}
            isLoading={authState?.isLoading}
            loadingText="Submitting"
            bgColor={'rgba(36, 107, 253, 1)'}
            color={'white'}
            fontSize={'14px'}
            lineHeight={'21px'}
            fontWeight={'500'}
            alignSelf={'center'}
            borderRadius={'16px'}
            width={'90%'}
            height={'54px'}
            isDisabled={
              !input?.store_name ||
              !input?.store_description ||
              !input?.store_phone ||
              !wallet?.account?.address
            }
          >
            Continue
          </Button>
        </Center>
      </Flex>
    </>
  );
}
