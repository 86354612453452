import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const filterMyStoreOrder = createAsyncThunk(
  'order/filterMyStoreOrder',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/store/order/filter/index`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
      },
      body: formData,
    });

    if (res?.status == 204) {
      return {
        current_page: 1,
        data: [],
      };
    }

    const response = await res.json();

    if (response?.status_code == 200) {
      return response?.data;
    }

    return thunkAPI.rejectWithValue('Unsuccessful retrieving orders');
  }
);

export const singleOrder = createAsyncThunk(
  'order/singleOrder',
  async (data, thunkAPI) => {
    return data;
  }
);

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    storeOrder: [],
    singleOrder: {},
    isLoading: false,
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(filterMyStoreOrder.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(filterMyStoreOrder.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload?.current_page !== 1) {
        state.storeOrder = {
          ...action.payload,
          data: [
            ...(state.storeOrder?.data ?? []),
            ...(action.payload.data ?? []),
          ],
        };
        return;
      }
      state.storeOrder = action.payload;
    });
    builder.addCase(filterMyStoreOrder.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(singleOrder.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(singleOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleOrder = action.payload;
    });
    builder.addCase(singleOrder.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default orderSlice.reducer;
