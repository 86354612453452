// Replace with your actual chat ID and bot token

const token =
  '6974700850:AAEK8EsT6974700850:AAEK8EsTjNPRGekiCzSNXk8fIJraL2ePI-A';

export const sendMessageWithButton = async (chatId, info) => {
  const url = `https://api.telegram.org/bot${token}/sendMessage`;
  const message = {
    chat_id: chatId,
    text: info,
    // text: 'Click the button below:',
    // reply_markup: {
    //   inline_keyboard: [
    //     [
    //       {
    //         text: 'Click Me',
    //         callback_data: 'button_click',
    //       },
    //     ],
    //   ],
    // },
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });
    const data = await response.json();
  } catch (error) {
    console.error('Error sending message:', error);
  }
};

// sendMessageWithButton(chatId, token);
